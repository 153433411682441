import { types } from "../types/types"

const initialState = {
    modalOpenDelete: false,
}

export const uiDeleteReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiOpenModalDelete:
            return {
                ...state,
                modalOpenDelete: true
            }

        case types.uiCloseModalDelete:
            return {
                ...state,
                modalOpenDelete: false
            }

        default:
            return state;
    }
}