import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";
//import { startChecking, startLogout} from "./auth";

export const storeStartLoading = () => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`store`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( storeLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

const storeLoaded = (store) => ({
    type: types.storeLoaded,
    payload: store
})

export const startRegisterStore = (code, name, address, town,  city) => {

    
    
    return async( dispatch ) => {

        const resp = await fetchConToken( `store`, { code, name, address, town,  city }, 'POST');
        const body = await resp.json();

        if( resp.status === 201 && body ){
            const msg = 'La tienda ' + name + ' se ha registrado correctamente.';
            Swal.fire('Registrada', msg, 'success')
        } else {
            Swal.fire('Error', body.detail, 'error')
        }
    }
}


export const storeStartUpdated = (store) => {

    return async( dispatch ) => {
        let id =  store.id
        try{
            const resp = await fetchConToken(`store/${id}`, store, 'PUT') ;
            const body = await resp.json();
            if ( resp.status === 200 && body ){
                dispatch( storeUpdated(body.user) )
            } else {
                Swal.fire('Error', body.msg, 'error')
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

const storeUpdated = ( store ) => ({
    type: types.storeUpdated,
    payload: store
})

export const storeSetActive = (store) => ({
    type: types.storeSetActive,
    payload: store
})

export const storeClearActive = (store) => ({type: types.storeClearActive})


export const storeDelete = (props) => {


    return async( dispatch, getState ) => {


        const { id, name } = props?.store;

        try{
            const resp = await fetchConToken(`store/${id}`, {}, 'DELETE');
            if ( resp.status === 200 ){
                Swal.fire('Eliminada', 'Tienda: ' + name + ' eliminada correctamente.', 'success')
                dispatch( storeDeleted() )
            } else {
                const body = await resp.json();
                Swal.fire('Error', body.detail, 'error')
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

export const storeDeleted = (store) => ({type: types.storeDeleted})