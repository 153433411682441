import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, HashRouter, Switch } from 'react-router-dom'
import { startChecking } from '../actions/auth'
import { LoginScreen } from '../components/auth/LoginScreen'
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { RegisterUserScreen } from '../components/register/RegisterUserScreen'
import { StockScreen } from '../components/stock/StockScreen'
import { MenuScreen } from '../components/menuAdmin/MenuScreen'
import { MenuDeliveryScreen } from '../components/menuAdmin/MenuDeliveryScreen'
import { DeliveryScreen } from '../components/deliveryNote/DeliveryScreen'
import { DeliveryNumberScreen } from '../components/deliveryNote/DeliveryNumberScreen'
import { DeliveryExpeditionScreen } from '../components/deliveryNote/DeliveryExpeditionScreen'
import { SsccScreen } from '../components/sscc/SsccScreen'
import { CmpScreen } from '../components/platesCmp/CmpScreen'
import { SheetScreen } from '../components/sheet/SheetScreen'
import { UserScreen } from '../components/user/UserScreen'
import { RegisterStoreScreen } from '../components/register/RegisterStoreScreen'
import { StoreScreen } from '../components/store/StoreScreen'
import { MovementScreen } from '../components/movements/MovementScreen'

export const AppRouter = () => {

    const dispatch = useDispatch();

    const { checking, token } = useSelector(state => state.auth)

    useEffect(() => {
        
        dispatch( startChecking() )
        
    }, [dispatch])

    if ( checking ) {
        return <h5>ESPERE...</h5>
    }
    return (
        <HashRouter>
            <div>
                <Switch>
                    
                    <PublicRoute exact path="/login" component={LoginScreen} isAuthenticated={ !!token }/>

                    <PrivateRoute exact path="/sheet" component={SheetScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/cmp" component={CmpScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/article/sscc" component={SsccScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/delivery/download/expedition" component={DeliveryExpeditionScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/delivery/download/number" component={DeliveryNumberScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/delivery/download/:orderDeliveryId - :storeId - :secId" component={DeliveryScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/delivery/download" component={DeliveryScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/delivery" component={MenuDeliveryScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/store/register" component={RegisterStoreScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/user/register" component={RegisterUserScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/movements/:productId - :storeId" component={MovementScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/movements" component={MovementScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/store" component={StoreScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/user" component={UserScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/stock" component={StockScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/" component={MenuScreen} isAuthenticated={ !!token }/>:userId

                    <Redirect to="/"/>
                </Switch>
            </div>
        </HashRouter>
    )
}
