const url =  "/config.json";

window.APP_CONFIG = {
    "daymovement": 15
}

fetch(url).then(
    resp => {
        if (resp.status === 200)
            resp.json().then(
                data => {
                    window.APP_CONFIG = data;
                }
            )            
    },
    err => {
        console.log("Error", err);
    }
)