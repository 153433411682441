import { types } from '../types/types'

const initialState = {
    ssccData: [],
}

export const ssccReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.ssccLoaded:
            return {
                ssccData: [...action.payload]
            }

        default:
            return state;
    }
}

export default ssccReducer;