import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import { filterStock } from '../../actions/stock';
import { filterDeliveryDonwload } from '../../actions/deliveryNotes';
import { filterDeliveryDonwloadN } from '../../actions/deliveryNotes';
import { filterDeliveryExpedition } from '../../actions/deliveryNotes';
import { filterSscc } from '../../actions/sscc';
import { filterCmp } from '../../actions/cmp';
import { userStartLoading } from '../../actions/userCrud';
import { filterMovement } from '../../actions/movement';

export const BtnReload = ({props, data}) => {

    const dispatch = useDispatch();
    const { store } = useSelector(state => state.auth);

    const handleClick = () => {

        if (props === 'store')
        {
            dispatch(storeStartLoading());
        } else if (props === 'user')
        {
            dispatch( userStartLoading());
        } else if (props === 'movement')
        {
            dispatch( filterMovement(data) )
        } 
        else if (props === 'delivery')
        {
            dispatch( filterDeliveryDonwload(data))
        } 
        else if (props === 'deliveryN')
        {
            dispatch( filterDeliveryDonwloadN(data))
        } 
        else if (props === 'deliveryE')
        {
            dispatch( filterDeliveryExpedition(data))
        } else if (props === 'ssccData')
        {
            dispatch( filterSscc(data))
        } else if (props === 'cmpData')
        {
            dispatch( filterCmp(data))
        } else 
        {
            dispatch( filterStock(data) )
        }
    }

    return (
        <button className="btn btn-danger btn-color" props={props} onClick={handleClick}>
            <ReloadOutlined />
        </button>
    )
}
