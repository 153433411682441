import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { startLogout } from "./auth";
import { spinnerLoaded } from "./spinner";

export const importDocument = (file) => {

    return async( dispatch, getState ) => {
        dispatch(spinnerLoaded(true));
        try{
            const resp = await fetchConToken(`stock/import`, file,'POST');
            if ( resp.status === 200){
                dispatch(spinnerLoaded(false));
                Swal.fire('Satifactorio', 'Archivo importado correctamente.', 'success');
            }else {
                dispatch(spinnerLoaded(false));
                const body = await resp.json();
                Swal.fire('Error', body[0], 'error')
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

export const importDocumentMovement = (file) => {

    return async( dispatch, getState ) => {
        dispatch(spinnerLoaded(true));
        try{
            const resp = await fetchConToken(`stockmovement/import`, file,'POST');
            if ( resp.status === 200){
                dispatch(spinnerLoaded(false));
                Swal.fire('Satifactorio', 'Archivo importado correctamente.', 'success');
            }else {
                dispatch(spinnerLoaded(false));
                const body = await resp.json();
                Swal.fire('Error', body[0], 'error')
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}