import React from 'react';
import './login.css';
import { useForm } from '../../hooks/useForm';
import { useDispatch } from 'react-redux';
import { startLogin } from '../../actions/auth';

export const LoginScreen = () => {

    const dispatch = useDispatch()

    const [ formLoginValues, handleLoginInputChange ] = useForm({

        lUser: '',
        lPassword: ''
    })

    const { lUser, lPassword } = formLoginValues;


    const handleLogin = ( e ) => {

        e.preventDefault();

        dispatch( startLogin(lUser, lPassword) );

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 login-form-1">
                    <img className='login-logo' alt="" src={require("../../assets/img/logo.png")}/>
                    <h3 className='login-title'>Acceso</h3>
                    <form onSubmit={ handleLogin }>
                        <div className="form-group-input">
                            <label className="control-label" >Usuario</label>
                            <input 
                                type="text"
                                className="form-control control-input"
                                placeholder="Usuario"
                                name="lUser"
                                value= { lUser }
                                onChange= { handleLoginInputChange }
                            />
                        </div>
                        <div className="form-group-input">
                            <label className="control-label" >Contraseña</label>
                            <input
                                type="password"
                                className="form-control control-input"
                                placeholder="Contraseña"
                                name="lPassword"
                                value= { lPassword }
                                onChange= { handleLoginInputChange }
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="submit"
                                className="btn btnSubmit"
                                value="Login" 
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginScreen;