const baseUrl = process.env.REACT_APP_API_URL


const fetchSinToken = async(endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;
    if(method === 'GET') {
        return fetch(url);
    } else if(endpoint === 'imagen') {
        return fetch(url, {
            method,
            body: data
        });
    } else{
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}

const fetchConToken = async(endpoint, data = {}, method = 'GET') => {

    let url = `${baseUrl}/${endpoint}`;
    const token = localStorage.getItem('token') || '';

    if(method === 'GET') {
        if(Object.keys(data).length > 0) {
            url = createQueryString(url, data);
        }
        return fetch(url, {
            method,
            headers: {
                'Authorization': 'Bearer ' + token
            },
        });
        //endpoint === 'imagen' || (endpoint.includes('producto') && data?.constructor?.name === 'FormData')
    } else if(data?.constructor?.name === 'FormData'){
        return fetch(url, {
            method,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data,
        });
    } else if(method === 'DOWNLOAD'){
        method = 'GET'
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/pdf',
                'Authorization': 'Bearer ' + token
            },
            body: data,
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
    }
}

function createQueryString(urlBase, data) {
    const queryString = Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
    return `${urlBase}?${queryString}`;
}

function base64ToBlob(base64Data, contentType) {
    const binaryString = window.atob(base64Data);
    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }

    return new Blob([binaryArray], { type: contentType });
}

function downloadDocuments(data) {
    if (!data.documents || !Array.isArray(data.documents))
        return;

    for (let i in data.documents) {
        const doc = data.documents[i];
        const blob = base64ToBlob(doc.base64, `application/${doc.format}`);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', doc.filename);
        document.body.appendChild(link);
        link.click();
    }
}

const fetchDocuments = async(endpoint, data = {}, failFunction = null) => {
    try {
        const resp = await fetchConToken(endpoint, data);

        if ( resp.status === 200) {
            const response = await resp.json();
            downloadDocuments(response);
            return true;
        }

        if (resp.body?.token === false && failFunction)
            failFunction();
    } catch (error) {
        console.error(error)
    }

    return false;
}


export {
    fetchSinToken, fetchConToken, downloadDocuments, fetchDocuments
}