import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";

export const roleStartLoading = () => {

    return async( dispatch) => {

        try{
            const resp = await fetchConToken(`role/`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( roleLoaded(body) )
            }else if (body?.token === false){
                    dispatch(startLogout())
                }
        } catch (error) {
            console.log(error)
        }  
    }
}

const roleLoaded = (role) => ({
    type: types.roleLoaded,
    payload: role
})