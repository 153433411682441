import React from 'react';
import { useDispatch } from 'react-redux';
import { filterDeliveryDonwload } from '../../actions/deliveryNotes';

import moment from 'moment';

export const BtnDeliveryNote = (props) => {

    const dispatch = useDispatch();

    const date = moment();
    let dateWeek = moment();
    const intervalMovement = window.APP_CONFIG.daymovement;
    dateWeek = dateWeek.subtract(intervalMovement, "days");

    const deliverynNotes = {
        store_id: props.store,
        sdate: '',
        edate: '',
        narticle: '',
        ean: '',
        sscc: '',
        ordercode: '',
        deliverynote: props.delivery?.deliverynote,
        providercode: '',
    }

    const handleClick = () => {

        dispatch( filterDeliveryDonwload(deliverynNotes))
    }

    return (
        <button key= {props.delivery} className="btn btn_link" props={props} onClick={handleClick}>
           { props.delivery?.deliverynote }
        </button>
    )
}
