import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImportModal } from '../import/ImportModal';
import { BtnLogout } from '../ui/BtnLogout';
import { ImportBtn } from '../ui/ImportBtn';
import { Spinner } from '../ui/Spinner';
import { StockBtn } from '../ui/StockBtn';
import { TiendaBtn } from '../ui/TiendaBtn';
import { UserBtn } from '../ui/UserBtn';
import { MovementBtn } from '../ui/MovementBtn';
import './menu.css';
import RolesConstants  from '../../constant/roles';
import { ImportMovementModal } from '../import/ImportMovementModal';
import { ImportMovementBtn } from '../ui/ImportMovementBtn';
import { DeliveryBtn } from '../ui/DeliveryBtn';
import { BtnSscc } from '../ui/BtnSscc';
import { BtnCmp } from '../ui/BtnCmp';
import { BtnSheet } from '../ui/BtnSheet';

export const MenuScreen = () => {

    const { rol } = useSelector(state => state.auth)
    const { loading } = useSelector(state => state.spinner);

    return  loading ? (
        <Spinner />
        ) : ( 
        <div className="container">
            
            <ImportModal/>
            <div className="row">
                <div className="col-md-4 menu-form">
                    <img className='menu-logo' alt="" src={require("../../assets/img/logo-min.png")}/>
                    <h3 className='menu-title'>Menu</h3>
                    { rol === RolesConstants.ADMIN &&
                        <div className="btnMenu">
                            <Link to='/user'>
                                <UserBtn></UserBtn>
                            </Link>
                        </div>
                    }
                    { rol === RolesConstants.ADMIN &&
                        <div className="btnMenu">
                            <Link to='/store'>
                                <TiendaBtn></TiendaBtn>
                            </Link>
                        </div>
                    }
                    <div className="btnMenu">
                        <Link to='/stock'>
                            <StockBtn></StockBtn>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/movements'>
                            <MovementBtn></MovementBtn>
                        </Link>
                    </div>
                    { rol === RolesConstants.ADMIN &&
                        <div className="btnMenu">
                            <ImportBtn></ImportBtn>
                        </div>
                    }
                    { rol === RolesConstants.ADMIN &&
                        <div className="btnMenu">
                            <ImportMovementBtn></ImportMovementBtn>
                        </div>
                    }
                    <div className="btnMenu">
                        <Link to='/delivery'>
                            <DeliveryBtn></DeliveryBtn>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/article/sscc'>
                            <BtnSscc></BtnSscc>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/cmp'>
                            <BtnCmp></BtnCmp>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/sheet'>
                            <BtnSheet></BtnSheet>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <BtnLogout/>
                    </div>
                </div>
                <ImportModal/>
                <ImportMovementModal/>
            </div>
        </div>    
    )
}