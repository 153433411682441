export const types = {

    uiOpenModal: '[ui] Open modal',
    uiCloseModal: '[ui] close modal',
    uiMovementOpenModal: '[ui] Open modal Movement',
    uiMovementCloseModal: '[ui] close modal Movement',
    uiOpenModalStore: '[ui] Open modal Store',
    uiCloseModalStore: '[ui] Close modal Store',
    uiOpenModalUser: '[ui] Open modal User',
    uiCloseModalUser: '[ui] Close modal User',
    uiOpenModalDelete: '[ui] Open modal Delete',
    uiCloseModalDelete: '[ui] Close modal Delete',

    authChecking: '[auth] Checking login state',
    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start Login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
    authstartTokenRenovar: '[auth] Start token renovar',
    authLogout: '[auth] Logout',

    stockLoaded: '[stock] Stock Loaded',

    movementLoaded: '[movement] Movement Stock Loaded',

    userLoaded: '[user] User Loaded',
    userUpdated: '[user] user Updated Loaded',
    userSetActive: '[user] Active user',
    userDeleted: '[user] Deleted User',

    roleLoaded: '[role] Role Loaded',

    storeLoaded: '[store] Store Loaded',
    storeUpdated: '[store] Store Updated Loaded',
    storeSetActive: '[store] Active Store',
    storeDeleted: '[store] Deleted Store',

    deliveryLoaded: '[delivery] Delivery Download Loaded',
    deliveryNLoaded: '[delivery] Delivery Download Number Loaded',
    deliveryExpeditionLoaded: '[delivery] Delivery Download Expedition Loaded',

    ssccLoaded: '[sscc] Sscc Loaded',
    cmpLoaded: '[cmp] Cmp Loaded',

    importLoaded: '[import] Import Document',

    spinnerLoaded: "[spinner] Loaded Spinner",
}

export default types;