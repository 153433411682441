import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { importDocumentMovement } from '../../actions/import';
import { uiMovementCloseModal } from '../../actions/ui';
import { Spinner } from '../ui/Spinner';
import "./import.css";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');


export const ImportMovementModal = () => {

    const { modalMovementOpen } = useSelector(state => state.ui);
    const { loading } = useSelector(state => state.spinner);
    const dispatch = useDispatch();
    const [File, setFile] = useState();
    

    useEffect(() => {

        setFile(undefined);
        
    }, [])

    const changeImagen = e => {
        setFile(e.target.files[0]);;
    }

    const closeModal = () => {
        dispatch(uiMovementCloseModal());
        setFile(undefined);
    }
    const handleSubmitForm = async (e) => {
        e.preventDefault();

        const form = new FormData();
        form.append('data', File);
        await dispatch( importDocumentMovement(form));

        setFile(undefined);
        closeModal();
    }

    return loading ? (
        <Spinner />
        ) : ( 
            <Modal 
            isOpen= {modalMovementOpen}
            onRequestClose={closeModal}
            style={customStyles}
            closeTimeoutMS={ 200 }
            className= "modal modal-import"
            overlayClassName= "modal-fondo"
            >
                <h1 className='title'> Importar Documento</h1>
                <hr />
                <form className="container" onSubmit={ handleSubmitForm }>

                    <div className="content-modal paddig-tp">
                        <header>
                            <input type="file" name="imagen"  onChange= {changeImagen}/>
                        </header>
                    </div>

                    <button
                        type="submit"
                        className="btn btn-danger margin_right_envelope btnColor"
                    >
                        <i className="far fa-save"></i>
                        <span className="navbar-brand user"> Importar</span>
                    </button>

                </form>
            </Modal>
        )
}