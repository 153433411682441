import React from 'react';

export const BtnSheet = (props) => {

    return (
        <button className="btn btn-danger margin_right_envelope btnColor" props={props}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                HOJA DE RECEPCIÓN
            </span>
        </button>
    )
}
