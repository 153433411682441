import React from 'react';
import { useDispatch } from 'react-redux';
import { filterCmp } from '../../actions/cmp';
import { storeStartLoading } from '../../actions/storeCrud';
import moment from 'moment';

export const BtnCmp= (props) => {

    const dispatch = useDispatch();
    const dateExp = moment().format("yyyy-MM-DD");

    const cmpData = {
        store_id: props.store !== undefined ? props.store :'',
        cmp: '',
        expdate: dateExp,
        expcode: '',
    }

    const handleClick = () => {
        dispatch(storeStartLoading());
        dispatch( filterCmp(cmpData))
    }

    return (
        <button className="btn  btn-danger margin_right_envelope btnColor" props={props} onClick={handleClick}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                MATRÍCULAS / CMP
            </span>
        </button>
    )
}
