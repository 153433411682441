import Swal from "sweetalert2";
import { fetchSinToken } from "../helpers/fetch"
import { types } from "../types/types";



export const startLogin = (user, pwd) => {

    return async(dispatch) => {

        const resp = await fetchSinToken('connect/token', { user, pwd }, 'POST');

        if (resp.status === 200) {
            const body = await resp.json();
            localStorage.setItem('token', body.access_token);
            localStorage.setItem('exp', body.expiration);

            dispatch(login({
                token: body.access_token,
                name: body.user_name,
                rol: body.role_id,
                store: body.store_id
            }))
        } else {
            Swal.fire('Error', 'El usuario o contraseña no coincide.', 'error');
        }
    }
}

const login = (user) => ({
    type: types.authLogin,
    payload: user
})

export const startChecking = () => {

    return async(dispatch) => {

        const body = false;

        if (body.ok) {
            localStorage.setItem('token', body.token);

            dispatch(login({
                uid: body.uid,
                name: body.name,
                apellidos: body.apellidos,
                tipo: body.tipo
            }))
        } else {
            dispatch(finishChecking())
        }
    }
}

const finishChecking = () => ({
    type: types.authCheckingFinish
})

export const startLogout = () => {

    return (dispatch) => {

        localStorage.clear();
        dispatch(logout())
    }
}

const logout = () => ({
    type: types.authLogout,
})