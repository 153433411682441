import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImportModal } from '../import/ImportModal';
import { Spinner } from '../ui/Spinner';
import { BtnBack } from '../ui/BtnBack';
import { BtnDeliveryDonwload } from '../ui/BtnDeliveryDonwload';
import { BtnDeliveryDonwloadNumber } from '../ui/BtnDeliveryDonwloadNumber';
import { BtnDeliveryExpedition } from '../ui/BtnDeliveryExpedition';
import './menu.css';
import { ImportMovementModal } from '../import/ImportMovementModal';

export const MenuDeliveryScreen = ({history}) => {

    const { rol } = useSelector(state => state.auth)
    const { loading } = useSelector(state => state.spinner);

    return  loading ? (
        <Spinner />
        ) : ( 
    <>
        <div className="container">
        <section id="general-hdr">
                <div className="container-fluid">
                    <div className="col">
                        <BtnBack history={history}/>
                        <h1 className="section-name">Albaranes</h1>                        
                    </div>                      
                </div>
            </section>
            <ImportModal/>
            <div className="row">
                <div className="col-md-4 menu-form">
                    <img className='menu-logo' alt="" src={require("../../assets/img/logo-min.png")}/>
                    <h3 className='menu-title'>Menu Albaranes</h3>
                    <div className="btnMenu">
                        <Link to='/delivery/download'>
                            <BtnDeliveryDonwload></BtnDeliveryDonwload>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/delivery/download/number'>
                            <BtnDeliveryDonwloadNumber></BtnDeliveryDonwloadNumber>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/delivery/download/expedition'>
                            <BtnDeliveryExpedition></BtnDeliveryExpedition>
                        </Link>
                    </div>
                </div>
                <ImportModal/>
                <ImportMovementModal/>
            </div>
        </div>
    </>    
    )
}