import React from 'react';
import { useDispatch} from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import './btn.css';

export const AddUser = () => {

    const dispatch = useDispatch()

    const handleAdd = () => {
        dispatch( storeStartLoading())
    }

    return (
        <button className="btn btn-success btn-margin-right" onClick={handleAdd}>
            <span>
                AGREGAR USUARIO
            </span>
        </button>
    )
}