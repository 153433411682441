import { Space } from 'antd';
import {  useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { EditUserModal } from '../modal/EditUserModal';
import { AddUser } from '../ui/AddUser';
import { BtnDelete } from '../ui/BtnDelete';
import { BtnEdit } from '../ui/BtnEdit';
import { BtnLogout } from '../ui/BtnLogout';
import { DataGrid, esES } from '@mui/x-data-grid';
import './user.css';
import { DeleteStoreModal } from '../modal/DeleteStoreModal';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';
import { useState } from 'react';

const columns = [
    {
        headerName: 'Nombre',
        field: 'name',
        flex: 1,
    },
    {
        headerName: 'Email',
        field: 'email',
        flex: 1,
    },
    {
        headerName: 'Tienda',
        field: 'store_code',
        flex: 1,
    },
    {
        headerName: 'Rol',
        field: 'role_name',
        flex: 1,
    },
    {
        headerName: 'Acción',
        field: 'action',
        flex: 1,
        renderCell: (record) => {
            return record?.row.role_id !== 1 && (
            <Space size="middle">
                <BtnEdit  user= { record.row }/>
                <BtnDelete  user= { record.row }/>
            </Space>)
            
        },
    },
];

export const UserScreen = ( {history} ) => {

    const {users} = useSelector(state => state.user);
    const [pageSize, setPageSize] = useState(10);

    return (
        <div>
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Usuarios</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <section id="action-button">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                        <Link to='/user/register'>
                            <AddUser></AddUser>
                        </Link>
                        <BtnReload props={'user'}/>                        
                        </div>                     
                    </div>
                </div>
            </section>
            <div>
            
            <div className='table-container'>
                <div className="table-style-user">
                <DataGrid
                        rows={users}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </div>
            <EditUserModal/>
            <DeleteStoreModal/>
        </div>
        </div>
    )
}
