import React from 'react';
import './register.css';
import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { startRegisterUser, userStartLoading } from '../../actions/userCrud';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';

export const RegisterUserScreen = ({history}) => {

    const dispatch = useDispatch();

    const {stores} = useSelector(state => state.store);
    const {roles} = useSelector(state => state.roles);

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        login: '',
        password: '',
        store_id: null,
        name: '',
        email: '',
        active: true,
        role_id: null
    })

    let { login, password, store_id, name,  email, active, role_id} = formRegisterValues;

    const handleRegister = async (e) => {

        e.preventDefault();

        store_id = store_id !== null ? Number(store_id) : null;

        await dispatch( startRegisterUser(login, password, store_id, name,  email, active, role_id) )
        dispatch( userStartLoading() );
    }

    return (
        <div>
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Registro de Usuario</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 register-form-2">
                        <form onSubmit={ handleRegister } className="mmk-form">
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value= { name }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Usuario</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Usuario"
                                        name="login"
                                        value= { login }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Contraseña</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Contraseña"
                                            name="password"
                                            value= { password }
                                            onChange= { handleRegisterInputChange }
                                        />
                                </div>
                            </div>  
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Roles </label>
                                    <select
                                        name="role_id"
                                        className='form-select form-select-md shop-sel'
                                        defaultValue={role_id}
                                        onChange= { handleRegisterInputChange }
                                    >
                                        {
                                            (
                                                roles.map((item,i)=>(
                                                <option key={"roles"+i} value={item.id} >{item.name} </option>
                                            ))
                                            )
                                        }
                                    </select>						
                                </div>
                            </div>
                            { role_id === "2" &&
                                <div className="row mb-2">
                                    <div className="col">
                                        <label className="form-label" >Tienda</label>
                                        <select
                                            className="form-select form-select-md shop-sel"
                                            placeholder="Tienda"
                                            name="store_id"
                                            onChange= { handleRegisterInputChange }
                                        >
                                            {
                                                (
                                                    stores.map((item,i)=>(
                                                    <option key={"stores"+i} value={item.id}>{item.name}</option>
                                                ))
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value= { email }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end mb-2">
                                <div className="col-12 ">
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary">Añadir Usuario</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}