import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../actions/auth';

export const BtnLogout = (props) => {

    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch( startLogout() )
    }

    return (
        <button className="btn btn-padding btn-logout" props={props} onClick={handleLogout}>
            <span>  Cerrar Sesión</span>
        </button>
    )
}
