import { useForm } from '../../hooks/useForm'
import {  useDispatch, useSelector, useMemo } from 'react-redux';
import { filterDeliveryExpedition } from '../../actions/deliveryNotes';
import './delivery.css';
import moment from 'moment';
import { BtnLogout } from '../ui/BtnLogout';
import RolesConstants  from '../../constant/roles';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';
import { Link } from 'react-router-dom';
import { BtnDeliveryNotes } from '../ui/BtnDeliveryNotes';
import { BtnDeliveryOrders } from '../ui/BtnDeliveryOrders';
import { Collapse, Space, Tooltip } from 'antd';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { QuickSearchToolbar } from '../filter/GlobalFilter';

const { Panel } = Collapse;
const secNote = 2;
const store_id = 0;

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

export const DeliveryExpeditionScreen = ( {history},  { expandedColumnIndex }) => {

    const { deliveryE } = useSelector(state => state.delivery);
    const { stores } = useSelector(state => state.store);
    const { rol } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);
    let dateIni = moment().format("yyyy-MM-DD");

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        store_id: '',
        expcode:'',
        expdate: dateIni
    });
    
    const { store_id, store, expcode, expdate } = formRegisterValues
    const columns = [
        {
            headerName: 'Nº EXPEDICIÓN',
            field: 'expcode',
            flex: 1,
        },
        {
            headerName: 'FECHA EXPEDICIÓN',
            field: 'expdate',
            flex: 1,
        },
        {
            headerName: 'DESTINO',
            field: 'destination',
            flex: 1,
        },
        {
            headerName: 'PEDIDO',
            field: 'orders',
            flex: 3,
            width:300,
            renderCell: (record) => (
                
                (
                    record.value.map((item, i)=>(
                        <Space key={i} size="middle">
                    
                            <Link to={`/delivery/download/${item} - ${record.row?.store_id} - ${secNote}`}>
                                <BtnDeliveryOrders key={i} order= { item} store = {store_id} />
                            </Link>
                        </Space>
                ))
                )
            ),
        },
        {
            headerName: 'Nº ALBARAN',
            field: 'deliverynotes',
            width:300,
            flex: 3,
            renderCell: (record) => (
                (
                    record.value.map((item, i)=>(
                        <Space key={i} size="middle">
                    
                            <Link to={`/delivery/download/${item} - ${record.row?.store_id} - ${secNote}`}>
                                <BtnDeliveryNotes key={i} delivery= { item} store = {store}/>
                            </Link>
                        </Space>
                ))
                )
            ),
        },
        {
            headerName: 'ESTADO',
            field: 'status',
            flex: 1,
            
        },
        {
            headerName: 'HORA EXPEDICIÓN',
            field: 'exphour',
            flex: 2,
        },
    ]

    const handleFilter = (e) => {
        e.preventDefault();
        
        dispatch( filterDeliveryExpedition(formRegisterValues) )
    }

    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Albaranes por Expedición</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <section id="filters-zone">
                <div className="container-fluid pt-2 pb-2 mb-2">

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <Collapse accordion>
                            <Panel header="Filtrar productos" key="1">
                            <div id="flush-collapseOne" className="accordion-collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <form onSubmit={ handleFilter } className="mmk-form">
                                        { (rol !== RolesConstants.STORE ) &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label className="form-label" >Tienda </label>
                                                <select
                                                    name="store_id"
                                                    defaultValue={store_id}
                                                    className='form-select form-select-md shop-sel'
                                                    onChange= { handleRegisterInputChange }
                                                >   
                                                    <option key={0} value=''>TODAS LAS TIENDAS</option>
                                                    {
                                                        (
                                                            stores.map((item,i)=>(
                                                            <option key={"stores"+i} value={item.id}>{item.code} - {item.name}</option>
                                                        ))
                                                        )
                                                    }
                                                </select>						
                                            </div>
                                        </div>
                                        }
                                        
                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Número de Expedición</label>
                                                <input
                                                    type="text"
                                                    name="expcode"
                                                    value= { expcode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Fecha de Expedición</label>
                                                <input
                                                    type="date"
                                                    name="expdate"
                                                    value= { expdate }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>								
                                        </div>
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-12 col-lg-3">
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">APLICAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>		
            </section>
            <div className='table-container'>
                <BtnReload props={'deliveryE'} data={formRegisterValues}/>
                <div className="table-style-stock margin-top">
                    <DataGrid
                        rows={deliveryE ?? []}
                        columns={columns}
                        getRowId={(row) => `${row.expcode}-${Math.random().toString(36).substr(2, 9)}`}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: QuickSearchToolbar }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}