import { types } from '../types/types'

const initialState = {
    delivery: [],
    deliveryN: [],
    deliveryE: [],
}

export const deliveryReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.deliveryLoaded:
            return {
                ...state,
                delivery: [...action.payload]
            }
        
        case types.deliveryNLoaded:
            return {
                ...state,
                deliveryN: [...action.payload]
            }

        case types.deliveryExpeditionLoaded:
            return {
                ...state,
                deliveryE: [...action.payload]
            }

        default:
            return state;
    }
}

export default deliveryReducer;