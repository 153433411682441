import { types } from '../types/types'

const initialState = {
    roles: [],
}

export const roleReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.roleLoaded:
            return {
                ...state,
                roles: [...action.payload]
            }

        default:
            return state;
    }
}

export default roleReducer;