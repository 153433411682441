import { useForm } from '../../hooks/useForm'
import {  useDispatch, useSelector } from 'react-redux';
import { filterStock } from '../../actions/stock';
import './stock.css';
import RolesConstants  from '../../constant/roles';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { BtnMovement } from '../ui/BtnMovement';
import { BtnReload } from '../ui/BtnReload';
import { Collapse, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { QuickSearchToolbar } from '../filter/GlobalFilter';

const { Panel } = Collapse;

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

const columns = [
    {
        headerName: 'TIENDA',
        field: 'store_code',
        flex: 1,
    },
    {
        headerName: 'ZONA',
        field: 'zone',
        flex: 1,
    },
    {
        headerName: 'PASILLO',
        field: 'street',
        flex: 1,
    },
    {
        headerName: 'POSICIÓN',
        field: 'position',
        flex: 1,
    },
    {
        headerName: 'ALTURA',
        field: 'altitude',
        flex: 1,
    },
    {
        headerName: 'PRODUCTO',
        field: 'productcode',
        flex: 1,
        
    },
    {
        headerName: 'EAN',
        field: 'eancode',
        flex: 1,
    },
    {
        headerName: 'DESCRIPCIÓN',
        field: 'description',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.description} >
                <span className="table-cell-trucate">{record.row.description}</span>
            </Tooltip>
        ),
        flex: 2,
    },
    {
        headerName: 'UNIDADES',
        field: 'units',
        flex: 1,
    },
    {
        headerName: 'BLOQUEO',
        field: 'block',
        flex: 1,
    },
    {
        headerName: 'Acción',
        field: 'action',
        flex: 1,
        renderCell: (record) => (
            <Space size="middle">
                <Link to={`/movements/${record.row.productcode} - ${record.row.store_id}`}>
                    <BtnMovement  movement= { record.row}/>
                </Link>
            </Space>
        ),
    },
]

export const StockScreen = ( {history} ) => {

    const { stocks } = useSelector(state => state.stock);
    const { stores } = useSelector(state => state.store);
    const { rol } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        store_id: '',
        zone: '',
        productcode:'',
        street: '',
        position: '',
        altitude: '',
        eancode: '',
        units: '',
        block: '',

    });
    
    const { productcode, zone, street, position, altitude, eancode, units, block } = formRegisterValues

    const handleFilter = (e) => {
        e.preventDefault();

        dispatch( filterStock(formRegisterValues) )
    }

    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Stock tiendas</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <section id="filters-zone">
                <div className="container-fluid pt-2 pb-2 mb-2">

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <Collapse accordion>
                            <Panel header="Filtrar productos" key="1">
                            <div id="flush-collapseOne" className="accordion-collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <form onSubmit={ handleFilter } className="mmk-form">
                                        { rol === RolesConstants.ADMIN &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label className="form-label" >Tienda </label>
                                                <select
                                                    name="store_id"
                                                    className='form-select form-select-md shop-sel'
                                                    onChange= { handleRegisterInputChange }
                                                >
                                                    <option key={0} value=''>TODAS LAS TIENDAS</option>
                                                    {
                                                        (
                                                            stores.map((item,i)=>(
                                                            <option key={"stores"+i} value={item.id}>{item.code} - {item.name}</option>
                                                        ))
                                                        )
                                                    }
                                                </select>						
                                            </div>
                                        </div>
                                        }
                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Zona</label>
                                                <input
                                                    type="text"
                                                    name="zone"
                                                    value= { zone }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Pasillo</label>
                                                <input
                                                    type="text"
                                                    name="street"
                                                    value= { street }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Posición</label>
                                                <input
                                                    type="text"
                                                    name="position"
                                                    value= { position }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Altura</label>
                                                <input
                                                    type="text"
                                                    name="altitude"
                                                    value= { altitude }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>										
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">EAN</label>
                                                <input
                                                    type="text"
                                                    name="eancode"
                                                    value= { eancode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Producto</label>
                                                <input
                                                    type="text"
                                                    name="productcode"
                                                    value= { productcode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Unidades</label>
                                                <input
                                                    type="text"
                                                    name="units"
                                                    value= { units }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Bloqueo</label>
                                                <input
                                                    type="text"
                                                    name="block"
                                                    value= { block }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>										
                                        </div>
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-12 col-lg-3">
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">APLICAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>		
            </section>
            <div className='table-container'>
                <BtnReload props={'stock'} data={formRegisterValues}/>
                <div className="table-style-stock margin-top">
                    <DataGrid
                        rows={stocks}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </div>
            </div>
        </div>
    )
}