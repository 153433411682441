import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";

export const ssccStartLoading = (id) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stockmovement/`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( ssccLoaded(body) )
            }else if (body?.token === false){
                    dispatch(startLogout())
                }
        } catch (error) {
            console.log(error)
        }  
    }
}

const ssccLoaded = (sscc) => ({
    type: types.ssccLoaded,
    payload: sscc
})

export const filterSscc = (data) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`article/sscc`, data);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( ssccLoaded(body) )
            }else if (body?.token === false){
                    dispatch(startLogout())
                }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}