import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { uiOpenModalStore } from '../../actions/uiStore';
import { storeSetActive, storeStartLoading } from '../../actions/storeCrud';
import { userSetActive } from '../../actions/userCrud';
import { uiOpenModalUser } from '../../actions/uiUser';

export const BtnEdit = (props) => {

    const dispatch = useDispatch()

    const handleClick = () => {

        if(props?.store){
            dispatch( storeSetActive(props))
            dispatch( uiOpenModalStore() )
        } else {
            dispatch( storeStartLoading())
            dispatch( userSetActive(props))
            dispatch( uiOpenModalUser() )
        }
    }

    return (
        <button className="btn btn-info btn-padding" props={props} onClick={handleClick}>
            <EditOutlined />
        </button>
    )
}
