import React from 'react';
import { useDispatch } from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import { filterSscc } from '../../actions/sscc';

export const BtnSscc = (props) => {

    const dispatch = useDispatch();

    const sscc = {
        store_id: props.store !== undefined ? props.store :'',
        sscc:'',
        narticle: '',
        providercode: '',
        lem: '',
        ordercode: '',

    };

    const handleClick = () => {
        dispatch(storeStartLoading());
        dispatch( filterSscc(sscc) )
    }

    return (
        <button className="btn btn-danger margin_right_envelope btnColor" props={props} onClick={handleClick}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                SSCC
            </span>
        </button>
    )
}
