import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { filterDownload } from '../../actions/deliveryNotes';

export const BtnDownload = (props) => {

    const dispatch = useDispatch();

    const delivery = {
        store_id: props.delivery?.store_id,
        deliverynote: props.delivery?.deliverynote,
    }

    const handleClick = () => {

        dispatch( filterDownload(delivery))
    }

    return (
        <button className="btn btn-danger btn-movement btn-padding" props={props} onClick={handleClick}>
            <DownloadOutlined />
        </button>
    )
}
