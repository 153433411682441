import React from 'react';
import { useDispatch } from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import './btn.css';

export const TiendaBtn = ( {props} ) => {

    const dispatch = useDispatch();

    const handleStore = () => {
        dispatch(storeStartLoading());
    }

    return (
        <button className="btn btn-danger btnColor" onClick={handleStore}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                TIENDA
            </span>
        </button>
    )
}