import { useForm } from '../../hooks/useForm'
import {  useDispatch, useSelector } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import './movement.css';
import RolesConstants  from '../../constant/roles';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';
import { Collapse, Tooltip } from 'antd';
import moment from 'moment';
import { filterMovement } from '../../actions/movement';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuickSearchToolbar } from '../filter/GlobalFilter';


const { Panel } = Collapse;

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

const columns = [
    {
        headerName: 'TIENDA',
        field: 'store_code',
        flex: 1,
    },
    {
        headerName: 'FECHA MOVIMIENTO',
        field: 'movedt',
        flex: 2,
        valueFormatter: params =>
            moment(params?.value).format("DD/MM/YYYY HH:mm:ss"),
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={moment(record.row.movedt).format("DD/MM/YYYY HH:mm:ss")} >
                <span className="table-cell-trucate">{moment(record.row.movedt).format("DD/MM/YYYY HH:mm:ss")}</span>
            </Tooltip>
        ),
    },
    {
        headerName: 'ARTICULO',
        field: 'productcode',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.productcode} >
                <span className="table-cell-trucate">{record.row.productcode}</span>
            </Tooltip>
        ),
        flex: 1
    },
    {
        headerName: 'DESCRIPCIÓN',
        field: 'description',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.description} >
                <span className="table-cell-trucate">{record.row.description}</span>
            </Tooltip>
        ),
        flex: 3,
    },
    {
        headerName: 'SENTIDO',
        field: 'direction',
        flex: 1
    },
    {
        headerName: 'MOTIVO',
        field: 'reason',
        flex: 1
    },
    {
        headerName: 'UNIDADES',
        field: 'units',
        flex: 1
    },
    {
        headerName: 'REFERENCIA MOVIMIENTO',
        field: 'reference',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.reference} >
                <span className="table-cell-trucate">{record.row.reference}</span>
            </Tooltip>
        ),
        flex: 2
    },
    {
        headerName: 'MOTIVO BLOQUEO',
        field: 'blockreason',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.blockreason} >
                <span className="table-cell-trucate">{record.row.blockreason}</span>
            </Tooltip>
        ),
        flex: 2,
    }
]

//const prueba = [{"id":1,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1496428","description":"OFZ143F 202L W MICROWAVE GRILL 20","direction":"+","reason":"*ZM","units":2,"reference":"E226 IMTRON 190061164","blockreason":"PRUEBA DE LA RAZON"},{"id":2,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1496430","description":"OFK 623 F NF","direction":"+","reason":"*ZM","units":8,"reference":"","blockreason":""},{"id":3,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1496432","description":"LAV OWM 7133 E","direction":"+","reason":"*ZM","units":1,"reference":"","blockreason":""},{"id":4,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1496965","description":"LAV CS 1482DE/1-","direction":"+","reason":"*ZM","units":5,"reference":"","blockreason":""},{"id":5,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1496970","description":"HW80-BP14636N","direction":"+","reason":"*ZM","units":8,"reference":"","blockreason":""},{"id":6,"movedt":"2022-06-21T18:11:15+03:00","store_id":3,"store_code":"E001","productcode":"1498551","description":"LAV DF325FW","direction":"+","reason":"*ZM","units":2,"reference":"","blockreason":""}]


export const MovementScreen = ( {history} ) => {

    const { movement } = useSelector(state => state.movement);
    const { productId, storeId } = useParams();
    const { stores } = useSelector(state => state.store);
    const { rol } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);

    const dateEnd = moment().format("yyyy-MM-DD");
    let dateIni = moment();
    const intervalMovement = window.APP_CONFIG.daymovement;

    dateIni = dateIni.subtract(intervalMovement, "days");
    dateIni = dateIni.format("yyyy-MM-DD");

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        store_id: storeId !== undefined ? storeId : '',
        sdate: dateIni,
        edate: dateEnd,
        productcode: productId !== undefined ? productId : '',
        description: '',
        direction: '',
        units: '',
        reference: '',
        blockreason: '',

    });
    
    const { store_id, sdate, edate, productcode, description, direction, units, reference, blockreason } = formRegisterValues

    const handleFilter = (e) => {
        e.preventDefault();
        dispatch( filterMovement(formRegisterValues) )
    }

    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Movimientos del Stock</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <section id="filters-zone">
                <div className="container-fluid pt-2 pb-2 mb-2">

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <Collapse accordion>
                            <Panel header="Filtrar productos" key="1">
                            <div id="flush-collapseOne" className="accordion-collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <form onSubmit={ handleFilter } className="mmk-form">
                                        { rol === RolesConstants.ADMIN &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label className="form-label" >Tienda </label>
                                                <select
                                                    name="store_id"
                                                    className='form-select form-select-md shop-sel'
                                                    defaultValue={store_id}
                                                    onChange= { handleRegisterInputChange }
                                                >
                                                    <option key={0} value=''>TODAS LAS TIENDAS</option>
                                                    {
                                                        (
                                                            stores.map((item,i)=>(
                                                            <option key={"stores"+i} value={item.id}>{item.code} - {item.name} </option>
                                                        ))
                                                        )
                                                    }
                                                </select>						
                                            </div>
                                        </div>
                                        }
                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Fecha de Inicio</label>
                                                <input
                                                    type="date"
                                                    name="sdate"
                                                    value= { sdate }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Fecha de Fin</label>
                                                <input
                                                    type="date"
                                                    name="edate"
                                                    value= { edate }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Articulo</label>
                                                <input
                                                    type="text"
                                                    name="productcode"
                                                    value= { productcode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Descripción</label>
                                                <input
                                                    type="text"
                                                    name="description"
                                                    value= { description }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>										
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Motivo</label>
                                                <input
                                                    type="text"
                                                    name="direction"
                                                    value= { direction }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Unidades</label>
                                                <input
                                                    type="text"
                                                    name="units"
                                                    value= { units }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Referencia</label>
                                                <input
                                                    type="text"
                                                    name="reference"
                                                    value= { reference }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Bloqueo</label>
                                                <input
                                                    type="text"
                                                    name="blockreason"
                                                    value= { blockreason }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>										
                                        </div>
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-12 col-lg-3">
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">APLICAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>		
            </section>
            <div className='table-container'>
                <BtnReload props={'movement'} data={formRegisterValues}/>
                <div className="table-style-stock margin-top">
                    <DataGrid
                        rows={movement}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </div>
            </div>
        </div>
    )
}