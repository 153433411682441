import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { filterDownloads } from '../../actions/deliveryNotes';
import { filterDownloadSheet } from '../../actions/sheet';
import { Tooltip } from 'antd';

export const BtnDownloads = (props) => {

    const dispatch = useDispatch();

    const handleClick = () => {

        if ( Object.values(props)[0] === 'sheet')
        {
            dispatch( filterDownloadSheet())

        } else {

            dispatch( filterDownloads(props))
        }
    }

    return (
        <button className="btn btn-color btn-danger btn-margin-left" props={props} onClick={handleClick}>
            <DownloadOutlined />
            <Tooltip placement="topLeft" title="Todas las descargas" >
                <span className="table-cell-trucate"> Todas</span>
            </Tooltip>
        </button>
    )
}
