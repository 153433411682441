import React from 'react';
import { useDispatch } from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import { filterDeliveryDonwloadN } from '../../actions/deliveryNotes';

export const BtnDeliveryDonwloadNumber = (props) => {

    const dispatch = useDispatch();

    const deliverynNotesNumber = {
        store_id: props.store !== undefined ? props.store :'',
        downloadcode:'',
        narticle: '',
        ordercode: '',
        deliverynote: '',
        providercode: '',
    }

    const handleClick = () => {
        dispatch(storeStartLoading());
        dispatch( filterDeliveryDonwloadN(deliverynNotesNumber) )
    }

    return (
        <button className="btn btn-danger margin_right_envelope btnColor" props={props} onClick={handleClick}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                POR NÚMERO DE DESCARGA
            </span>
        </button>
    )
}
