import moment from 'moment';
import React from 'react';
import { useDispatch} from 'react-redux';
import { filterMovement } from '../../actions/movement';
import { storeStartLoading } from '../../actions/storeCrud';

import './btn.css';

export const MovementBtn = () => {

    const dispatch = useDispatch()

    const date = moment();
    let dateWeek = moment();
    const intervalMovement = window.APP_CONFIG.daymovement;
    dateWeek = dateWeek.subtract(intervalMovement, "days");
    
    const movement = {
        store_id: '',
        sdate: dateWeek,
        edate: date,
        productcode: '',
        description: '',
        direction: '',
        units: '',
        reference: '',
        blockreason: '',
    }

    const handleMovement = () => {
        dispatch(storeStartLoading());
        dispatch( filterMovement(movement) )
    }

    return (
        <button className="btn btn-danger margin_right_envelope btnColor" onClick={handleMovement}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                MOVIMIENTOS DEL STOCK
            </span>
        </button>
    )
}