import './sheet.css';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { BtnDownloads } from '../ui/BtnDownloads';

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>


export const SheetScreen = ( {history} ) => {

    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Hoja Recepción</h1>                        
                        </div>
                        <BtnDownloads props={'sheet'}/>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
        </div>
    )
}